<script setup>
import { ref, onMounted } from 'vue';
import Cookies from 'js-cookie';

const isVisible = ref(false);
const privacyPolicyURL = '/privacy-policy';

function dismissCookieWarning() {
  const options = { expires: 365, sameSite: 'strict' };
  Cookies.set('warning', true, options);
  isVisible.value = false;
  cw.value.addEventListener('animationend', () => {
    cw.value.remove();
  });
  cw.value.classList.add('fadeOutDown');
}

const cookieWarningRef = ref(null);

onMounted(() => {
  cookieWarningRef.value = document.getElementById('cookie-warning');
  if (cookieWarningRef.value && !Cookies.get('warning')) {
    isVisible.value = true;
    const cwBtn = cookieWarningRef.value.querySelector('.dismiss-cookie-warning');
    cwBtn.addEventListener('click', dismissCookieWarning);
    cwBtn.addEventListener('tap', dismissCookieWarning);
    cookieWarningRef.value.style.display = 'block';
  }
});
</script>

<template>
  <div
    ref="cookieWarningRef"
    id="cookie-warning"
    class="container-fluid animated"
    :class="{ 'fadeInUp': isVisible, 'fadeOutDown': !isVisible }"
    v-bind:style="{ visibility: isVisible ? 'visible' : 'hidden' }"
  >
    <div class="border-gradient"></div>
    <div class="row py-4 mx-md-auto">
      <div class="col-md-9 col-12 py-3">
        <div class="text-content pb-md-0">
          To provide you with the best experience, we use internal and third-party cookies and may collect information about you. By using our site, you agree to our use of cookies. Click <a :href="privacyPolicyURL" target="_blank" rel="nofollow noopener noreferrer">HERE</a> to learn more.
        </div>
      </div>
      <div class="col-md-2 col-12 text-center d-md-flex flex-column justify-content-center">
        <button @click="dismissCookieWarning" class="btn btn-primary small">Continue</button>
      </div>
    </div>
    <span class="icon-close dismiss-cookie-warning" role="button" @click="dismissCookieWarning"></span>
  </div>
</template>

<style lang="scss">
#cookie-warning {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #f7f7f7;
  z-index: 800;
  font-size: $font-size-small;
  box-shadow: 0 0 13px 0 rgba(0,0,0,.15);
  animation-duration: .4s;

  &.fadeOutDown {
    animation-duration: .3s;
  }

  .border-gradient {
    top: 0;
    left: 0;
    width: 100%;
  position: absolute;
  height: 8px;
  background-image: linear-gradient(to right, #5691ef 3%, #54c797 66%, #51ff3b 132%); 

  }

  .row {
    max-width: 1440px;
    .text-content {
      color: black;
    }
  }

  .dismiss-cookie-warning {
    cursor: pointer;
  }

  .icon-close {
    position: absolute;
    right: 15px;
    top: 20px;
    color: black;
  }
}
</style>
